// extracted by mini-css-extract-plugin
export var absoluteSubmit = "news-module--absoluteSubmit--zXNqF";
export var actions = "news-module--actions--iAiv7";
export var chevron = "news-module--chevron--jSm8X";
export var focusVisible = "news-module--focus-visible--AAiRn";
export var hsErrorMsg = "news-module--hs-error-msg--ocCOf";
export var hs_submit = "news-module--hs_submit--xMPrB";
export var icon = "news-module--icon--1Oqer";
export var input = "news-module--input--xGVdM";
export var news = "news-module--news--PY7hV";
export var news__author = "news-module--news__author--FBPF9";
export var news__content = "news-module--news__content--zFVNN";
export var news__cta = "news-module--news__cta--BJe-v";