import React from 'react';
import PropTypes from 'prop-types';
import NavigationBlock from '../../components/navigationBlock/navigationBlock';

import {lowerLanguageArray} from '../../lib/maps/languageMap';
import {breadcrumbs, breadcrumbsReversed} from './breadcrumbs.module.scss';

const BreadCrumb = ({className, colorprofile, location, ...other}) => {

  const slugs = location?.pathname.split('/');
  let links = [];

  //convert slug to breadcrumb display text
  const createDisplayText = (path) => {
    let finalPath = path.replaceAll('-', ' '); 
    return finalPath.replaceAll('and', "&");
  }

  slugs?.forEach(path => {
    if(path !== "" && !lowerLanguageArray.includes(path)){
      links.push(
        {
          link : [{
            slug : path,
            displayText : createDisplayText(path),
          }]
        }
      ) 
    }
  });

  return (
    <>
      {
        links.length > 1 &&
        <NavigationBlock className={`${className} ${breadcrumbs} ${colorprofile === 'Reversed' ? breadcrumbsReversed : ''}`} {...other} aria-label="Bread crumb" linkclass="breadcrumb__link" icon={false} data={links} data-addsearch="exclude" />
      }
    </>
  )
}

BreadCrumb.propTypes = {
  className: PropTypes.string,
   /**
   * Gatsby location object
   */
  location: PropTypes.object.isRequired,
   /**
   * Default - on light backgrounds, Reversed - for dark backgrounds
   */
  colorprofile: PropTypes.oneOf(['Default', 'Reversed'])
}

BreadCrumb.defaultProps = {
  colorprofile: 'Default',
  className: 'breadcrumbs'
}

export default BreadCrumb;
