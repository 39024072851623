// extracted by mini-css-extract-plugin
export var absoluteSubmit = "breadcrumbs-module--absoluteSubmit--e3whj";
export var actions = "breadcrumbs-module--actions--lgYsm";
export var breadcrumbs = "breadcrumbs-module--breadcrumbs--cHyr2";
export var breadcrumbsReversed = "breadcrumbs-module--breadcrumbs--reversed--ahN5f";
export var chevron = "breadcrumbs-module--chevron--kCJWS";
export var focusVisible = "breadcrumbs-module--focus-visible--Yn-AC";
export var hsErrorMsg = "breadcrumbs-module--hs-error-msg--P-4Fu";
export var hs_submit = "breadcrumbs-module--hs_submit--FKoeM";
export var icon = "breadcrumbs-module--icon--IQuv5";
export var input = "breadcrumbs-module--input--jPz4G";