// extracted by mini-css-extract-plugin
export var absoluteSubmit = "posts-module--absoluteSubmit--R2tl-";
export var actions = "posts-module--actions--U-g6C";
export var chevron = "posts-module--chevron--yIpaA";
export var focusVisible = "posts-module--focus-visible--MwhNc";
export var hsErrorMsg = "posts-module--hs-error-msg--pI2Wq";
export var hs_submit = "posts-module--hs_submit--I1yOs";
export var icon = "posts-module--icon--As7XI";
export var input = "posts-module--input--ufl1Z";
export var post = "posts-module--post--gKhpi";
export var post__cats = "posts-module--post__cats--z1GZ5";
export var post__relatedProducts = "posts-module--post__relatedProducts--C-4Z1";