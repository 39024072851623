import React, {lazy, Suspense, useContext} from "react"
import {catMatch} from "../../lib/functions/util-functions";
import Hero from "../../modules/hero/hero";
import {post, post__relatedProducts, post__cats} from './posts.module.scss';
import Link from "../../components/link/link";
import { LangContext } from '../../context/LocaleContext';

const RelatedContent = lazy(() => import("./components/relatedContent/relatedContent"));

const PostTemplate = ({allPosts, categories, children, className, featuredImage, hero, heroSize, id, location, pagecontext, title }) => {
  const { getTranslation } = useContext(LangContext);

  const catSlugs = categories?.map((item)=>item.slug);
  let featuredFlag = null;

  const getParentSlug = (url, slug) => {
    return( url.substring(0, url.lastIndexOf(`/${slug}`)) );
  }

  let parentSlug = getParentSlug(pagecontext.nonLocalePath, pagecontext.slug);
   //function to order by news posts with any overlapping categories with current post
   const mapOrder = (order, key) => (a, b) =>  order.some( r => (catMatch([b], 'categories', r, 'OR', key).length > 0)  > ( catMatch([a], 'categories', r, 'OR', key)).length > 0 )  ? 1 : -1;
   let sortedPosts = allPosts.filter((item) => {return item.id !== id});
   sortedPosts = sortedPosts.sort(mapOrder(catSlugs, 'originalId'));

   //conditionally add the featured tag
   if(catSlugs.includes(process.env.GATSBY_FEATURED_CAT)){
    featuredFlag = categories.filter(obj => {return obj.slug === process.env.GATSBY_FEATURED_CAT})[0];
   }

   if(hero){
     hero = hero[0];
   }

   //Build out hero data
   const heroData = {
    'backgroundImage': featuredImage,
    'cta' : [
      {
        'heading': title,
        ...hero
      }
    ]
   }

  return (
    <article className={`${post} ${className}`}>
      <Hero className={`hero`} data={heroData} size={heroSize} location={location} >
        {
          (featuredFlag && parentSlug) &&
          <Link icon={false} className="btn--flag" href={`${parentSlug}?cat=${featuredFlag.slug}`}>{featuredFlag.title}</Link>
        }
      </Hero>
      {children}
      {
      (parentSlug && categories.length > 0) &&
        <section className={`${post__cats} container`} data-addsearch="exclude" >
          <div className="wrapper">
            <h2 className="h3">{getTranslation('Categories')}:</h2>
            {
              categories?.map((category, i)=>{
                return <Link key={i} href={`${parentSlug}?cat=${category.slug}`} icon={false} className="btn--chip">{category.title}</Link>
              })
            }
          </div>
        </section>
      }
      {
        sortedPosts &&
        <Suspense fallback="">
          <RelatedContent className={post__relatedProducts} data={sortedPosts}/>
        </Suspense>
      }
    </article>
  )
}

export default PostTemplate;