import React, { useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import useOnClickOutside from "../../../../lib/hooks/useOnClickOutside";
import {shareBar, copyClipboard, copyClipboard__tooltip} from './shareBar.module.scss';
import { LangContext } from '../../../../context/LocaleContext';

import Button from "../../../../components/button/button";
import Link from "../../../../components/link/link";
import LinkIcon from "../../../../images/LinkIcon.inline.svg";
import LinkedIn from "../../../../images/linkedinIcon.inline.svg";
import MailIcon from "../../../../images/mailIcon.inline.svg";
import TwitterIcon from "../../../../images/TwitterIcon.inline.svg";

const ShareBar = ({className, description, title, url, ...other}) => {
  const [copySuccess, setCopySuccess] = useState('');
  const node = useRef(null);
  const { getTranslation } = useContext(LangContext);

  /**
  * Takes in text to copy to clipboard, and text to display on 
  */
  function copyToClipboard(text, toolTipText) {
    if(text){
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(text);
      setCopySuccess(toolTipText);
    }else{
      setCopySuccess(null);
    }
  }

  useOnClickOutside(node, ()=>copyToClipboard());


  return (
    <div className={`${shareBar} ${className || ''}`} data-addsearch="exclude" {...other}>
      {/* linked in (pulls title and description from meta tags) */}
      <Link icon={false} className="shareIcon" href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}><span className="sr_only">{getTranslation('ShareLinkedIn')}</span><LinkedIn aria-hidden="true"/></Link>
      {/* twitter */}
      <Link icon={false} className="shareIcon" href={`https://twitter.com/intent/tweet?url=${url}&text=${title}`}><span className="sr_only">{getTranslation('ShareTwitter')}</span><TwitterIcon aria-hidden="true"/></Link>
      {/* email */}
      <Link icon={false} className="shareIcon" href={`mailto:?subject=75F Article: ${title}&body=${description} : ${url}`}><span className="sr_only">{getTranslation('ShareEmail')}</span><MailIcon aria-hidden="true"/></Link>
      <div className={copyClipboard} ref={node}>
        {/* copy link */}
        <Button icon={false} className="shareIcon" onClick={()=>copyToClipboard(url, `${getTranslation('Copied')}!`)}><span className="sr_only">{getTranslation('CopyLink')}</span><LinkIcon aria-hidden="true"/></Button>
        {copySuccess &&
        <div className={copyClipboard__tooltip}>
          {copySuccess}
        </div>
        }
      </div>
    </div>
  )
}

ShareBar.propTypes = {
   /**
   * optional classname
   */
  className: PropTypes.string,
   /**
   * Page Title
   */
  title: PropTypes.string,
  /**
   * current page url
   */
  url: PropTypes.string
}

export default ShareBar
