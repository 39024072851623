import React, {lazy, Suspense, useContext} from "react";
import { graphql } from 'gatsby';
import ShareBar from "../components/shareBar/shareBar";
import PostTemplate from "../posts";
import {news, news__author, news__content, news__cta} from "./news.module.scss";
import {LangContext} from "../../../context/LocaleContext";

const ContentBlock = lazy(() => import("../../../components/contentBlock/contentBlock"));
const StructuredText = lazy(() => import("../../../components/structuredText/structuredText"));

const News = ({data, location, pageContext}) => {
  const {getTranslation} = useContext(LangContext)

  const {author, content, cta, meta, seo, title, ...postTemplateData} = data?.datoCmsPosttypeNews || {};
  return (
    <PostTemplate className={news} heroSize={'Medium'} title={title} pagecontext={pageContext} location={location} allPosts={data.allDatoCmsPosttypeNews.newsPosts} {...postTemplateData}>
      <div className={`${news__content} container`}>
        <ShareBar title={title} url={location.href} description={seo ? seo.description : ''} />
          <time className="h4" dateTime={meta.createdAt} data-addsearch="exclude" >{meta.createdAt}</time>
          <Suspense fallback="">
            <StructuredText className="wysiwyg" data={content}/>
            {
              author && <span className={`h4 ${news__author}`} data-addsearch="exclude" >{getTranslation("By") || "By"} <address>{author}</address></span>
            }
            {
              cta.length > 0 && <ContentBlock data={cta[0]} className={news__cta} />
            }
          </Suspense>
          </div>  
    </PostTemplate>
  )
}

export default News;

export const query = graphql`
  query($id: String, $categories : [String]) {

    datoCmsPosttypeNews(originalId : {eq : $id}) {
      author
      categories{
        originalId
        slug
        title
      }
      content{
        blocks{
          __typename
          ...ImageLayoutFragment
          ...TableFragment
          ...VideoFragment
          ...LinkFragment
        }
        links
        value
      }
      cta{
        ...CopyLinkFragment
      }
      featuredImage{
        ...BackgroundImageFragment
      }
      id
      locale
      meta{
        createdAt(formatString: "MMMM D, YYYY")
      }
      model{
        name
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      } 
      seo{
        description
      }
      slug
      title
    }
    
    allDatoCmsPosttypeNews(
      filter: {
        originalId: {ne: $id}, 
        categories: {elemMatch: {slug: {in: $categories}}},
        featuredImage: {url: {ne : "null" }},
      }
      limit: 10
      ){
        newsPosts: nodes{
        featuredImage{
          ...ThirdImageFragment
        }
        categories{
          slug
        }
        seo{
          description
        }
        slug
        title
      }
    }
  }
`