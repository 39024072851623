import React, { useState, useEffect } from "react"
import "../CountdownTimer/CountdownTimer.scss";
import PropTypes from 'prop-types';
import { graphql } from "gatsby";

const CountdownTimer = ({children, className, data, eyebrow,countdown,countdownHeader,countdownHead,headingTag, location, size, ...other}) => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      let totalSeconds = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds
      const isodate = countdownHead;
      const date = new Date(isodate);
const options = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZoneName: 'short',
};

const formattedDate = date.toLocaleDateString('en-US', options);
      
      let daysleft = new Date(formattedDate).getTime()
      let timenow = new Date().getTime()
      let timeleft = daysleft - timenow;
      totalSeconds--;
      setDays(Math.floor(timeleft / (1000 * 24 * 60 * 60)))
      setHours(Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
      setMinutes(Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)))
      setSeconds(Math.floor((timeleft % (1000 * 60)) / 1000))
    }, 1000)
    return () => clearInterval(interval)
  }, [days, hours, minutes, seconds])

  return (
    <>
    <div>
    <div className="Countdown_Main">
    <h1 className="Countdown-Head">{countdownHeader}</h1>
    <h1 className="Countdown-Head">{countdown}</h1>
    <div id="clockdiv">
    <div>
      <span className="digit" id="day">{days}</span>
      <div className="smalltext">Days</div>
      </div>
      <div>
        <span className="digit" id="hour">{hours}</span>
        <div className="smalltext">Hours</div>
      </div>
    <div>
        <span className="digit" id="minute">{minutes}</span>
        <div className="smalltext">Minutes</div>
      </div>
    <div>
        <span className="digit" id="second">{seconds}</span>
        <div className="smalltext">Seconds</div>
      </div>
      </div>
      </div>
      </div></>
           
  );
};

CountdownTimer.propTypes = {
  /**
  * Insert any content (placement below heading)
  */
  children: PropTypes.any,
  /**
  * Classname
  */
  className: PropTypes.string,
  /**
  * Data from cms
  */
  data: PropTypes.shape({
    countdownHead: PropTypes.oneOf(['Oct 31, 2023 12:00:00', 'Oct 31, 2023 5:00:00']),
  }).isRequired,
  /**
  * type of heading
  */
  countdownHead: PropTypes.any
};


CountdownTimer.defaultProps = {
  countdownHead: null,
  countdownHeader: null,
};

export default CountdownTimer;

export const HeroFragment = graphql`
fragment HeroFragment on DatoCmsModuleHero {
  backgroundImage{
    ...BackgroundImageFragment
  }
  cta{
    countdown
    countdownHeader
    countdownHead
    ...EyebrowHeadingCopyLinkFragment
  }
  heroSize
  wistiaId
}
`;